<template>
  <ion-app>
    <ion-split-pane disabled="true" content-id="main-content">
      <ion-menu
        side="start"
        content-id="main-content"
        type="overlay"
        class="hbmenu"
      >
        <ion-header>
          <ion-toolbar color="">
            <ion-buttons slot="end">
              <ion-menu-toggle>
                <ion-button>
                  <ion-icon :icon="closeCircle"></ion-icon>
                </ion-button>
              </ion-menu-toggle>
            </ion-buttons>
            <ion-title> &nbsp; </ion-title>
          </ion-toolbar>
          <ion-progress-bar
            :color="danger"
            v-if="cargando"
            type="indeterminate"
          ></ion-progress-bar>
        </ion-header>
        <ion-content>
          <ion-item color="">
            <ion-thumbnail slot="start">
              <ion-img src="static/imago1.png"></ion-img>
            </ion-thumbnail>
            <ion-label color="danger">
              <h2 class="ion-text-capitalize">{{ usuario.nombre }}</h2>
              <p class="ion-text-capitalize">{{ usuario.rol }}</p>
            </ion-label>
            <ion-button slot="end" color="danger" fill="clear" @click="logout">
              <ion-icon :icon="logOut" />
            </ion-button>
          </ion-item>
          <HbMenu :usuario="usuario" :empresa="empresa" />
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content" />
    </ion-split-pane>
  </ion-app>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap");
* {
  font-family: "Ubuntu", system-ui;
}
</style>
<script>
// Sobreescribir la función alert default
import {
  alertController,
  toastController,
  IonApp,
  IonSplitPane,
  IonMenu,
  IonHeader,
  IonTitle,
  IonProgressBar,
  IonContent,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonButton,
  IonToolbar,
  IonButtons,
  IonRouterOutlet,
  IonIcon,
  IonImg,
  IonMenuToggle,
} from "@ionic/vue";
import { logOut, closeCircle } from "ionicons/icons";
const presentAlert = async (subHeader = "", header = "Aviso") => {
  const alert = await alertController.create({ subHeader, header });
  await alert.present();
};
const presentToast = async (
  message = "",
  duration = 2000,
  color = "medium",
  position = "top"
) => {
  const toast = await toastController.create({
    message,
    duration,
    color,
    position,
  });
  await toast.present();
};
window.alert2 = window.alert;
window.alert = presentAlert;
window.toast = presentToast;
//

import Api from "@/components/Api/Api";
import ApiOffline from "@/components/Api/ApiOffline";
import HbMenu from "@/components/Menu/HbMenu";
import DeviceUUID from "device-uuid";
const empresa = Api.fetchLocal("empresadb") || {};

let currencySymbol = "$";
try {
  const empr = Api.fetchLocal("empresadb");
  if (empr && empr.monedaSimbolo) currencySymbol = empr.monedaSimbolo;
  console.log("Cambiado símbolo de moneda", currencySymbol);
} catch (e) {
  console.warn("No se pudo cambiar el símbolo default de la moneda local", e);
}

// Mejorar los mensajes de depuración
if (Api.modoProduccion) {
  // Eliminar los CLOGs sólo en producción
  console.log("Modo producción. Enmudeciendo console.log");
  console.log2 = console.log;
  // eslint-disable-next-line
  console.log = function () {};
}

export default {
  name: "App",
  components: {
    HbMenu,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonHeader,
    IonTitle,
    IonProgressBar,
    IonContent,
    IonItem,
    IonThumbnail,
    IonLabel,
    IonButton,
    IonToolbar,
    IonButtons,
    IonRouterOutlet,
    IonIcon,
    IonImg,
    IonMenuToggle,
  },
  data() {
    return {
      empresa,
      appName: Api.appName,
      modoProduccion: Api.modoProduccion,
      servidor: Api.servidor,
      esBdLocal: localStorage.getItem("engine") == "localStorage",
      usuario: {},
      cargando: false,
      ladoMenu: "left",
      titulo: "",
      //
      logOut,
      closeCircle,
    };
  },
  methods: {
    goTo(lugar) {
      this.$router.push(lugar);
    },
    img(str) {
      return str.startsWith("http") || str.startsWith("data")
        ? str
        : this.cdn + "/" + str;
    },
    async logout() {
      this.cargando = true;
      const borrarDatosLocales = true;
      if (borrarDatosLocales) {
        localStorage.setItem("modoOffline", 0);
        localStorage.setItem("catalogosInicializados", false);
        Api.deleteLocal("productos");
        Api.deleteLocal("branding");
        Api.deleteLocal("menusDinamicos");
        Api.deleteLocal("menusDinamicosIndices");
        Api.deleteLocal("titulo");
        if (!Api.esBdLocal) Api.deleteLocal("empresadb");
        const catalogosBorrables = [
          "producto",
          "almacen",
          "familia",
          "directorio", // Primarios, inamovibles excepto por un Administrador
          "metodopago",
          "lineaprecio",
          "grupocaja",
          "plantilladocx", // Auxiliares, el business logic puede seguir sin ellos
          "proveedor",
          "cliente", // Manipulables por el punto de venta
          "proyecto",
          "partida",
          "partidaoc",
          "pago",
          "facturama", // Las ventas per se
        ];
        for (const key in localStorage)
          for (const catalogo of catalogosBorrables)
            if (key.startsWith("" + catalogo + ".")) {
              Api.deleteLocal(key);
              localStorage.removeItem(key);
            }
        Api.deleteLocal("modulos");
      }
      await Api.wait(500);
      Api.deleteLocal("yo");
      this.$router.push("/login");
      this.cargando = false;
      window.location.reload();
    },
    inicio() {
      this.$router.push("/hola");
    },
    toggleModoDark(val) {
      this.modoDark = val;
      document.body.classList.toggle("dark", val);
      Api.saveLocal("modoDark", val == true ? "si" : "no");
    },
    async obtenerUsuario(evt) {
      await Api.wait(500);
      this.usuario = Api.fetchLocal("yo") || {};
      console.log(
        "Recuperada identidad",
        this.usuario.nombreLargo || this.usuario.nombre,
        this.usuario._id,
        this.$route.path
      );
      if (!this.modoProduccion)
        console.log("recuperada identidad", this.usuario);
      this.$forceUpdate();
      if (this.usuario._id && !(this.usuario.jwt && this.usuario.jwt != "")) {
        this.$router.push("/login");
        window.toast("Inicie sesión nuevamente");
      } else {
        if (
          this.$route.path == "/" &&
          this.usuario.nombre &&
          this.usuario.nombre != ""
        ) {
          window.toast("Hola de nuevo, " + this.usuario.nombre);
          this.$router.push("/hola");
        }
      }
      if (evt && typeof evt != "undefined") evt.complete();
      return true;
    },
    async promptActualizarServiceWorker() {
      const yo = Api.fetchLocal("yo") || {};
      console.info("promptActualizarServiceWorker?", yo._id, yo);
      if (yo._id)
        try {
          const installingWorker =
            window.serviceWorkerCurrentRegistration.installing;
          console.info(
            "Actualiando service worker? installingWorker",
            installingWorker
          );
          installingWorker.onstatechange = (_) => {
            console.info("serviceWorker onstatechange", installingWorker.state);
            return new Promise((resolve) => {
              switch (installingWorker.state) {
                case "installed":
                  if (navigator.serviceWorker.controller) {
                    const prmpt = confirm(
                      "Se ha encontrado una actualización de la app. ¿Desea actualizar ahora?\n\nDeberá volver a iniciar sesión"
                    );
                    if (true == prmpt) {
                      const catalogosBorrables = [
                        "producto",
                        "familia",
                        "cliente",
                        "proveedor",
                      ];
                      for (const catalogo of catalogosBorrables)
                        for (const key in localStorage)
                          if (key.startsWith("" + catalogo)) {
                            console.log("Borrando localmente", key);
                            Api.deleteLocal(key);
                          }
                      Api.deleteLocal("yo");
                    }
                    window.location.reload();
                    resolve(true);
                  } else {
                    // no update available
                    resolve(false);
                  }
                  break;
                default:
                  break;
              }
            });
          };
        } catch (e) {
          console.error(
            "No se pudo pedir la actualizacion del Service Worker al usuario"
          );
          console.error(e);
        }
    },
    calcularMenusDinamicos() {
      const menusDinamicosIndices =
        Api.fetchLocal("menusDinamicosIndices") || {};
      this.menusDinamicos = Object.values(menusDinamicosIndices).sort((a, b) =>
        a.cardinality > b.cardinality ? 1 : -1
      );
      console.log("calcularMenusDinamicos?", this.menusDinamicos);
      for (let i in this.menusDinamicos)
        this.menusDinamicos[i].routes =
          this.menusDinamicos[i].routes ||
          [].map((u) => {
            if (u.component) delete u.component;
            if (u.children)
              u.children = u.children.map((c) => {
                if (c.component) delete c.component;
                return c;
              });
            return u;
          });
      Api.saveLocal("modulos", this.menusDinamicos);
    },
    obtenerTitulo() {
      this.titulo = localStorage.getItem("titulo");
    },
    setTitulo(titulo = "SACVI3") {
      this.titulo = titulo;
    },
  },
  beforeMount() {
    if (this.appBloqueada) this.$router.push("/pinlock");
    Api.saveLocal("descargarBranding", true);
    setInterval(this.obtenerTitulo, 2000);
  },
  async mounted() {
    await Api.wait(500);
    this.$forceUpdate();
    if (this.modoDark == true) this.toggleModoDark(true);
    console.log("Haakon APP montada.", {
      modoOffline: this.modoOffline,
      modoDark: this.modoDark,
      firebaseAuth: this.yoGoogle,
    });
    const yo = Api.fetchLocal("yo") || {};
    console.log("Yo?", yo.nombre || yo);
    if (!yo?._id) {
      await Api.wait();
      this.$router.push("/login");
    }
    await this.obtenerUsuario();
    if (yo && yo._id) {
      if (!yo.dispositivos) yo.dispositivos = {};
      const myUUID = DeviceUUID.DeviceUUID().get();
      yo.dispositivos[myUUID] = DeviceUUID.DeviceUUID().parse();
      console.log("Actualizados dispositivos", yo.dispositivos);
      await Api.saveLocal("yo", yo);
    } else {
      // Asegurarse que no haya modo offline que impida iniciar sesión
      Api.deleteLocal("modoOffline");
      Api.modoOffline = false;
      ApiOffline.modoOffline = false;
    }
    if (yo && yo.email == "admin@local" && !yo.rol && yo.jwt) {
      yo.rol = "administrador";
      Api.saveLocal("yo", yo);
      this.usuario = yo;
      window.toast("Default administrative role assigned");
    }
    await Api.wait();
    if (!(yo && yo._id && yo.email)) {
      console.log("No se encontró identidad de cliente", yo, this.$route);
      //if (this.$route.path.indexOf('gcarrsa') == -1)
    } else if (this.$route.name == "Login") {
      console.log("Hola de nuevo", yo.email);
      this.$router.push("/hola");
    }
    console.log("Estoy en", this.empresaNombre);
    // Si ya hay sesión, ir directo a ella
    this.calcularMenusDinamicos();
    // Service worker detectando actualizaciones
    if (navigator.serviceWorker) {
      try {
        window.addEventListener("ready", async (_) => {
          console.info("sw?", navigator.serviceWorker);
          window.serviceWorkerCurrentRegistration = await navigator
            .serviceWorker.ready;
          console.info(
            "serviceWorkerCurrentRegistration?",
            window.serviceWorkerCurrentRegistration,
            navigator.serviceWorker
          );
          if (window.serviceWorkerCurrentRegistration) {
            window.serviceWorkerCurrentRegistration.onupdatefound =
              this.promptActualizarServiceWorker();
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  },
  watch: {
    $route: async function (a, b) {
      console.log("$watch route", b.path, a.path);
    },
  },
};
</script>
